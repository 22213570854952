// Vietnamese
export default{
    form: {
        startSurveyButton: "Bắt đầu",
        nextButton: "Tiếp tục",
        submitAnswerButton: "Gửi câu trả lời",
        continueButton: "Tiếp tục",
        finishButton: "Hoàn thành",
        headerPercentageCompletion: 'hoàn thành',
        headerQuestionsCompleted: 'câu hỏi',
        headerDefaultErrorMessage: 'Ôi. Có lỗi xảy ra.',
        brandingPoweredBy: 'Được cung cấp bởi',
        brandingPromoWhySurvey: 'Tại sao khảo sát khi bạn có thể sử dụng <b>{product}</b>?',
        brandingPromoGetStarted: 'Bắt đầu ngay hôm nay!',
        submissionPending: "Đang gửi biểu mẫu giọng nói của bạn. Vui lòng không đóng cửa sổ trình duyệt.",
        submissionCanCloseWindow: "Biểu mẫu giọng nói của bạn đã được gửi thành công. Bạn có thể đóng cửa sổ bây giờ 👍",
        mediaAttachmentOverlayCloseButton: "Đóng",
        expiredFormHeader: "Cảm ơn bạn đã ghé qua!",
        expiredFormMessage: "Có vẻ như khảo sát này đã hết hạn. Vui lòng liên hệ với người tạo biểu mẫu để thông báo cho họ.",
        notPublishedFormHeader: "Biểu mẫu giọng nói chưa được xuất bản!",
        notPublishedFormMessage: "Có vẻ như khảo sát này chưa được xuất bản. Vui lòng liên hệ với người tạo biểu mẫu để thông báo cho họ.",
        notPublishedFormRetryButton: "Thử lại",
        errorFormHeader: "Ôi!",
        errorFormMessage: "Có thể đã xảy ra sự cố. Vui lòng thử lại sau.",
        errorFormRetryButton: "Thử lại",
        emptyFormHeader: "Cảm ơn bạn đã ghé qua!",
        emptyFormMessage: "Ôi! Có vẻ như biểu mẫu này hiện đang trống, không có câu hỏi hoặc trường nào để điền vào. Chúng tôi xin lỗi vì sự bất tiện này. Vui lòng liên hệ với người tạo biểu mẫu để thông báo vấn đề. Cảm ơn bạn vì sự hiểu biết và hợp tác của bạn!",

        restoreFormHeader: "Có vẻ như bạn đang có một biểu mẫu đang làm dở!",
        restoreFormMessage: "Bạn có muốn tiếp tục từ nơi bạn đã dừng lại không?",
        restoreFormButton: "Tiếp tục biểu mẫu",
        restoreFormButtonNew: "Bắt đầu lại",

        audioPermissionHeader: "Bạn có muốn trả lời bằng giọng nói không?",
        audioPermissionMessage: "Nói sẽ giúp trả lời nhanh chóng và dễ dàng.",
        audioPermissionAcceptButton: "Vâng, tôi muốn",
        audioPermissionDenyButton: "Không, cảm ơn",
        audioPermissionErrorHeader: "Không thể sử dụng micrô",
        audioPermissionErrorMessage: "Vui lòng kiểm tra quyền trình duyệt của bạn để cho phép truy cập vào micrô.",
        audioPermissionErrorButton: "Đồng ý",
        audioRecorderFailedUpload: "Tải lên bản ghi âm thất bại",
        silenceDetectorError: "Không nghe thấy bạn. Vui lòng kiểm tra cài đặt micrô của bạn. Mẹo: có thể là micrô ngoài.",
        audioRecorderButtonRecord: "Bắt đầu ghi âm",
        audioRecorderButtonRecordMore: "Ghi thêm",
        voiceResponseSelectionMessage: "Chọn cách bạn muốn trả lời...",
        voiceResponseSelectionOrDivider: "hoặc",
        voiceResponseTextInputPlaceholder: "Nhập câu trả lời",
        voiceResponseTextInputCharacterCounterMinMessage: "Vui lòng nhập ít nhất {min} ký tự",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} ký tự (tối thiểu {min} ký tự)",
        voiceResponseTextInputCharacterValidationMessage: "{count} ký tự (tối thiểu {min} ký tự)",

        // checkbox
        checkboxValidationTooFew: "Bạn phải chọn ít nhất {min} tùy chọn",
        checkboxValidationTooMany: "Bạn phải chọn không quá {max} tùy chọn",
        checkboxNoneOfTheAboveOption: "Không có lựa chọn nào ở trên",
        checkboxInvalidAnswer: "Câu trả lời không hợp lệ.",

        // datepicker
        datePickerPlaceholder: "Chọn ngày",

        // dropdown
        // email
        emailLabel: "Email",
        emailInvalid: "Email không hợp lệ.",
        // file-upload
        fileUploadPluginNameCamera: "Máy ảnh",
        fileUploadPluginNameCameraVideo: "Ghi video",
        fileUploadPluginScreenCast: "Chia sẻ màn hình",
        fileUploadVideoImportFilesDefault: 'Ghi hoặc tải lên video qua:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Chọn một tùy chọn ghi video:',
        fileUploadVideoImportFilesNoCamera: 'Nhấn nút để quay màn hình video',
        fileUploadVideoImportFilesNoScreenCast: 'Nhấn nút để ghi video',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Không có tùy chọn nào để ghi video',

        // matrix
        matrixValidationMessage: "Câu trả lời hợp lệ.",
        matrixRow: "Hàng",
        matrixColumn: "Cột",
        matrixRowRequired: "Hàng {rowTitle} là bắt buộc.",
        matrixRadioGroup: "Nhóm radio",
        matrixCheckboxGroup: "Nhóm checkbox",
        matrixGroupRequired: "Đối với hàng {rowTitle}. {type} {groupTitle} là bắt buộc.",
        matrixColumnRequired: "Đối với hàng {rowTitle}. Cột {columnTitle} là bắt buộc.",
        matrixColumnInvalid: "Đối với hàng {rowTitle}. Cột {columnTitle} không hợp lệ.",
        matrixRequired: "Bắt buộc.",
        matrixNumericMustBeNumber: "Phải là một số.",
        matrixNumericMustBeGreaterThenMin: "Phải lớn hơn {min}.",
        matrixNumericMustBeLessThenMax: "Phải nhỏ hơn {max}.",
        matrixNumericMustBeInteger: "Phải là số nguyên.",
        matrixNumericInvalidNumber: "Số không hợp lệ.",

        // name
        nameLabel: "Tên",
        nameInvalid: "Vui lòng nhập tên và họ.",

        // nps
        npsNotLikely: "Không khả thi",
        npsExtremelyLikely: "Rất khả thi",

        // numeric input
        numericInputRequired: "Bắt buộc.",
        numericInputMustBeNumber: "Phải là một số.",
        numericInputMustBeGreaterThenMin: "Phải lớn hơn {min}.",
        numericInputMustBeLessThenMax: "Phải nhỏ hơn {max}.",
        numericInputMustBeInteger: "Phải là số nguyên.",
        numericInputInvalidNumber: "Số không hợp lệ.",
        numericInputPlaceholder: "Nhập số",

        // phone
        phoneInvalid: "Số điện thoại không hợp lệ.",
        phoneCountrySelectorLabel: 'Mã quốc gia',
        phoneCountrySelectorError: 'Chọn quốc gia',
        phoneNumberLabel: 'Số điện thoại',
        phoneExample: 'Ví dụ:',

        // boolean
        booleanYesLabel: "Có",
        booleanNoLabel: "Không",

        //questionStep
        questionStepAudioQuestionLabel: "Câu hỏi âm thanh",

        // errors
        invalidPhoneNumber: "{phone} là số điện thoại không hợp lệ.",
        invalidEmail: "{email} là địa chỉ email không hợp lệ.",
        questionIsRequired: "Câu hỏi này là bắt buộc.",
        answerIsNotValid: "Câu trả lời không hợp lệ.",
        unfinishedProbingDialogError: "Vui lòng hoàn thành cuộc hội thoại.",
        cannotResumePartialResponse: "Không thể tiếp tục trả lời một phần.",
        failedToSubmitForm: "Không thể gửi biểu mẫu. Vui lòng kiểm tra kết nối internet của bạn và thử lại."
    }
}
